import React from 'react'
import Page from '../components/Page'
import SEO from '../components/seo'
import Protocols from '../components/Protocols'

const ProtocolsPage = () => (
  <Page>
    <SEO title="Opentrons Labworks" />
    <Protocols />
  </Page>
)

export default ProtocolsPage
