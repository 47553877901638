export const FEATURE_CARD_DATA = [
  {
    cardName: 'Ready to try out the Protocol Designer?',
    button: {
      children: 'Sign up now',
      Component: 'a',
      linkOutProps: {
        url: 'https://designer.opentrons.com/',
        gtm: {
          action: 'click',
          category: 'b-app',
          label: 'api-docs-button',
        },
      },
    },
  },
  {
    cardName: `Curious what's possible using the Opentrons API?`,
    cardDescription:
      'Read the Opentrons API Documentation to start writing and editing your own Python protocols.',
    button: {
      children: 'Explore the api',
      Component: 'a',
      linkOutProps: {
        url: 'https://docs.opentrons.com',
        gtm: {
          action: 'click',
          category: 'b-app',
          label: 'protocol-library-button',
        },
      },
    },
  },
]
