// @flow

import * as React from 'react'
import cx from 'classnames'

import GetInTouch from '../GetInTouch'
import {
  ContentHalf,
  ProtocolsTable,
  FeatureCard,
  PD_COMPARE_DATA,
} from '../../ui-components'

import API_IMAGE from './assets/image-api.jpg'
import PD_IMAGE from './assets/image-pd.jpg'

import {FEATURE_CARD_DATA} from './protocol-data'

import * as styles from './Protocols.module.css'

export default function Protocols() {
  return (
    <>
      <div className={styles.container}>
        <h2 className={styles.h2}>
          Two options for creating your first protocol
        </h2>
        <div className={styles.two_options}>
          <ContentHalf
            className={cx(styles.first_content_half, styles.content_half)}
          >
            <h5 className={styles.h5}>Protocol Designer</h5>
            <span className={styles.protocol_difficulty}>difficulty: easy</span>
            <p className={styles.p}>
              Use the Opentrons Protocol Designer BETA tool to generate simple
              protocols to run on your OT-2. No coding knowledge required!
            </p>
            <div className={styles.robot_types}>
              <ContentHalf className={styles.types}>
                <h6 className={styles.h6}>file type: json</h6>
              </ContentHalf>
              <ContentHalf className={styles.types}>
                <h6 className={styles.h6}>compatibility: OT-2</h6>
              </ContentHalf>
            </div>
            <div>
              <img className={styles.image} src={PD_IMAGE} alt="" />
            </div>
            <p className={styles.p}>
              The Protocol Designer is currently in beta, but contains all the
              features you need to instruct simple transfers from source to
              destination plates, mix, pause - along with more advance options
              like tip positioning, serialization, well order control and much
              more.
            </p>
          </ContentHalf>
          <ContentHalf className={styles.content_half}>
            <h5 className={styles.h5}>Protocol Library &amp; Python API</h5>
            <span className={styles.protocol_difficulty}>
              DIFFICULTY: MEDIUM/ADVANCED
            </span>
            <p className={styles.p}>
              For more complex protocols and workflows, use the Opentrons API to
              generate protocols using the Python coding language. Get unlimited
              customization, flexibility and control.
            </p>
            <div className={styles.robot_types}>
              <ContentHalf>
                <h6 className={styles.h6}>file type: python</h6>
              </ContentHalf>
              <ContentHalf>
                <h6 className={styles.h6}>compatibility: ot-one &amp; ot-2</h6>
              </ContentHalf>
            </div>
            <div>
              <img className={styles.image} src={API_IMAGE} alt="" />
            </div>
            <p className={styles.p}>
              If you need more flexibility and control in designing your
              workflows, this option is for you. We recommend that you choose
              this path if you are comfortable with Python.
            </p>
          </ContentHalf>
        </div>
        <h3 className={styles.h3}>
          Lets compare some features and capabilities
        </h3>
        <div>
          <ProtocolsTable data={PD_COMPARE_DATA} />
          <p className={styles.note}>
            *Note: Protocol Designer JSON Files and Opentrons API Python files
            are not compatible
          </p>
        </div>
        <div className={styles.feature_card_container}>
          {FEATURE_CARD_DATA.map((card, i) => {
            return (
              <ContentHalf key={i} className={styles.feature_card_content}>
                <FeatureCard {...card} />
              </ContentHalf>
            )
          })}
        </div>
      </div>
      <GetInTouch
        centeredContent={true}
        title="Discuss your protocol needs with us"
        secondaryTitle="We're excited to discuss your workflow needs, guide you through creating your first protocol, or even write your first protocol for you."
        demo
      />
    </>
  )
}
