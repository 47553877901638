// extracted by mini-css-extract-plugin
export var container = "Protocols-module--container---kKX3";
export var h2 = "Protocols-module--h2--FO4Ow";
export var h3 = "Protocols-module--h3--ohdhF";
export var image = "Protocols-module--image--PmDOT";
export var h5 = "Protocols-module--h5--KI3mU";
export var h6 = "Protocols-module--h6--rGDjh";
export var p = "Protocols-module--p--z3EoH";
export var two_options = "Protocols-module--two_options--VNwU7";
export var robot_types = "Protocols-module--robot_types--WvOET";
export var protocol_difficulty = "Protocols-module--protocol_difficulty--mcZg4";
export var content_half = "Protocols-module--content_half--ha9Ky";
export var first_content_half = "Protocols-module--first_content_half--pYRbQ";
export var types = "Protocols-module--types--FlW0G";
export var note = "Protocols-module--note--4wi8P";
export var feature_card_container = "Protocols-module--feature_card_container--lXmft";
export var feature_card_content = "Protocols-module--feature_card_content--GZJaA";